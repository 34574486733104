import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api/api";

export const fetchStats = createAsyncThunk(
  "report/fetchStats",
  async (__, { rejectWithValue }) => {
    try {
      const response = await api.get("/admin/admin_stats");
      console.log(response);
      if (response.data.status_code === 0) {
        return response.data.data;
      }
      return rejectWithValue(response.data.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// export const fetchSalesTrend = createAsyncThunk(
//   "report/fetchSalesTrend",
//   async ({ period }, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/admin/all_sales_trend?period=${period}`);
//       console.log(response);
//       if (response.data.status_code === 0) {
//         return { period, data: response.data.data };
//       }
//       throw new Error(response.data.message || "Failed to fetch sales trend");
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const fetchSalesTrend = createAsyncThunk(
  "report/fetchSalesTrend",
  async ({ period }, { rejectWithValue }) => {
    try {
      // Validate the period if necessary
      const validPeriods = ["monthly", "weekly", "yearly"];
      if (!validPeriods.includes(period)) {
        return rejectWithValue("Invalid period specified");
      }

      // Construct the API request based on the provided period
      const response = await api.get(`/admin/all_sales_trend?period=${period}`);
      console.log(response);

      if (response.data.status_code === 0) {
        // Return period-specific data
        return { period, data: response.data.salesTrend };
      } else {
        throw new Error(response.data.message || "Failed to fetch sales trend");
      }
    } catch (error) {
      // Return the error message to be handled in the reducer
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const fetchTransactionReport = createAsyncThunk(
  "report/fetchTransactionReport",
  async ({ startDate = "", endDate = "" } = {}, { rejectWithValue }) => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);

      const queryString = params.toString() ? `?${params.toString()}` : "";
      const response = await api.get(`/reports/payment_report${queryString}`);

      if (response.data.status_code === 0) {
        return response.data.salesTrend;
      } else if (response.data.status_code === 1) {
        throw new Error("Merchant has no order history");
      }
      throw new Error(
        response.data.message || "Failed to fetch transaction report"
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTopProducts = createAsyncThunk(
  "report/fetchTopProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/admin/top_products");
      if (response.data.status_code === 0) {
        return response.data.topSellingProducts || [];
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBottomProducts = createAsyncThunk(
  "report/fetchBottomProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("admin/bottom_products");
      if (response.data.status_code === 0) {
        return response.data.bottomSellingProducts || [];
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reportReducer = createSlice({
  name: "report",
  initialState: {
    salesTrend: {
      weekly: [],
      monthly: [],
      yearly: [],
    },
    stats: null,
    loading: false,
    error: null,
    transactionReport: [],
    topProducts: [],
    bottomProducts: [],
    reportLoading: false,
    reportError: null,
    topProductsError: null,
    bottomProductsError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesTrend.pending, (state) => {
        state.reportLoading = true;
      })
      .addCase(fetchSalesTrend.fulfilled, (state, action) => {
        state.reportLoading = false;
        const { period, data } = action.payload;
        // Correctly assign the data to the period
        state.salesTrend[period] = data;
      })
      .addCase(fetchSalesTrend.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })
      .addCase(fetchStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStats.fulfilled, (state, action) => {
        state.loading = false;
        state.stats = action.payload;
      })
      .addCase(fetchStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchTransactionReport.pending, (state) => {
        state.reportLoading = true;
        state.reportError = null;
        state.transactionReport = [];
      })
      .addCase(fetchTransactionReport.fulfilled, (state, action) => {
        state.reportLoading = false;
        state.transactionReport = action.payload || [];
      })
      .addCase(fetchTransactionReport.rejected, (state, action) => {
        state.reportLoading = false;
        state.reportError = action.payload;
      })
      .addCase(fetchTopProducts.pending, (state) => {
        state.loading = true;
        state.topProductsError = null;
      })
      .addCase(fetchTopProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.topProducts = action.payload;
      })
      .addCase(fetchTopProducts.rejected, (state, action) => {
        state.loading = false;
        state.topProductsError = action.payload;
      })
      .addCase(fetchBottomProducts.pending, (state) => {
        state.loading = true;
        state.bottomProductsError = null;
      })
      .addCase(fetchBottomProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.bottomProducts = action.payload;
      })
      .addCase(fetchBottomProducts.rejected, (state, action) => {
        state.loading = false;
        state.bottomProductsError = action.payload;
      });
  },
});

export default reportReducer.reducer;
