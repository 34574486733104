import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const verify_rider = createAsyncThunk(
  "rider/verify_rider",
  async (zippy_wallet_number, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(
        "/merchant_details",
        zippy_wallet_number,
        {
          withCredentials: true,
        }
      );
      if (data.status_code === 0) {
        return fulfillWithValue(data);
      } else if (data.status_code === 1) {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Register Rider
export const register_rider = createAsyncThunk(
  "rider/register_rider",
  async (
    {
      zippy_wallet_number,
      vehicle_type,
      license_number,
      names,
      email,
      providus_account_no,
      status,
    },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await api.post(
        "/delivery/register_rider",
        {
          zippy_wallet_number,
          vehicle_type,
          license_number,
          names,
          email,
          providus_account_no,
          status,
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.status_code === 0) {
        return fulfillWithValue(response.data);
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred during registration."
      );
    }
  }
);

export const fetchRiders = createAsyncThunk(
  "rider/fetchRiders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/delivery/all_riders");
      return response.data.Riders;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const activateRider = createAsyncThunk(
  "rider/activateRider",
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await api.post(`/delivery/activate_rider/${merchantId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deactivateRider = createAsyncThunk(
  "rider/deactivateRider",
  async (merchantId, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/delivery/deactivate_rider/${merchantId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const riderReducer = createSlice({
  name: "rider",
  initialState: {
    phoneNumber: "",
    successMessage: "",
    errorMessage: "",
    loader: false,
    riderInfo: null,
    riders: [],
    status: "idle",
    success: null,
    error: null,
  },
  reducers: {
    setPhoneNum: (state, action) => {
      state.phoneNumber = action.payload;
    },
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    resetRiderInfo: (state) => {
      state.riderInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verify_rider.pending, (state) => {
        state.loader = true;
      })
      .addCase(verify_rider.rejected, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 1) {
          state.errorMessage = payload.message;
        } else {
          state.errorMessage = "An error occurred while verifying the rider.";
        }
      })
      .addCase(verify_rider.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 0) {
          state.successMessage = payload.message;
          state.riderInfo = {
            names: payload.result?.[0].names,
            email: payload.result?.[0].email,
            address: payload.result?.[0].address,
            account_no: payload.result?.[0].account_no,
          };
        }
      })
      .addCase(register_rider.pending, (state) => {
        state.loader = true;
      })
      .addCase(register_rider.rejected, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 1) {
          state.errorMessage = payload.message;
        } else {
          state.errorMessage = "An error occurred while registering the rider.";
        }
      })
      .addCase(register_rider.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 0) {
          state.successMessage = payload.message;
        }
      })
      .addCase(fetchRiders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRiders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.riders = action.payload;
      })
      .addCase(fetchRiders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(activateRider.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(activateRider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deactivateRider.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deactivateRider.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { messageClear, resetRiderInfo, setPhoneNum } =
  riderReducer.actions;
export default riderReducer.reducer;
